import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import descriptionData from "../content/description.json"
import ProductContainer from "../components/productContainer"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"

const PartyDresses = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  buildSchema(edges)
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.collectivedress.com/party-dresses"
        />
        <title>{descriptionData.party.pageTitle}</title>
        <meta name="description" content={descriptionData.party.pageDescription1}/>
               
      </Helmet>
      <Seo description={descriptionData.party.pageDescription} />

      <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
        <h1 class="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.party.heading}
        </h1>
        <p className="hidden md:block">{descriptionData.party.description1}</p>
        <p className="text-l mt-5 mb-5 font-bold">
        Collective Dress introduces a variety of new dress styles every Friday. This provides a fresh selection of fashionable items for customers to browse through. Be sure to check in each week for unique updates to your wardrobe.
        </p>
      </div>

      <div className="product-container grid grid-cols-2 gap-2 md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} dressType="Party"/>
      </div>
      <p className="lg:hidden">{descriptionData.party.description1}</p>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "dress_party" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
            product_images_2nd
            product_images_3rd
            product_images_4th
            product_images_5th
            product_images_6th
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.party.pageTitle}
    description={descriptionData.party.pageDescription}
  />
)

export default PartyDresses
